<template>
  <dashboard-page-wrapper title="Automations">
    <div>
      <div class="row template-links">
        <div class="col-12 d-flex mb-4">
          <base-button v-if="isGreenHouseEnabled" type="link"
                       :class="activeMenu==='stage_changes' ? 'active' : 'not-active'"
                       @click="toggleMenuClass('stage_changes')">Stage Changes
          </base-button>
          <base-button type="link" :class="activeMenu==='interview' ? 'active' : 'not-active'"
                       @click="toggleMenuClass('interview')">Interview Reminders
          </base-button>
          <base-button v-if="accountType === accountTypes.TEAM" type="link"
                       :class="activeMenu==='onboarding' ? 'active' : 'not-active'"
                       @click="toggleMenuClass('onboarding')">Onboarding Messages
          </base-button>
        </div>
      </div>
      <!-- Interview Reminders Section -->
      <div v-if="activeMenu === 'interview'">
        <v-dialog v-model="dialog" persistent>
          <v-card class="bg-gradient-white">
            <v-card-title class="lighten-2 dialog-title">
              <AlertCircleOutlineIcon></AlertCircleOutlineIcon>&nbsp;&nbsp;Timezone required
            </v-card-title>
            <v-divider class="v-divide"></v-divider>
            <v-card-text>
              <a @click="$router.push({name: 'Home'})">Click here</a> to confirm your timezone for message scheduling.
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-content-end">
              <base-button class="btn-outline-primary" @click="$router.push({name: 'Home'})">
                Close
              </base-button>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <b-row v-if="!dialog && !isGreenHouseEnabled">
          <b-col md="12">
            <card bodyClasses="p-3 pb-3">
              <base-switch v-model="enableInterviewReminder" onText="On" offText="Off"
                           @input="isInterviewReminderEnabled">
              </base-switch>
              <ValidationObserver ref="validator">
                <div>
                  <b-row class="mt-3">
                    <b-col md="5">
                      <interview-reminders-list v-model="selectedTemplate" :on="enableInterviewReminder"
                                                :interviewReminders="interviewReminders" :formBlocked="formBlocked"
                                                :unsavedFormData="unsavedFormData"
                                                @save-template-confirmation="saveChangesConfirmation"/>
                    </b-col>
                    <b-col md="7" v-if="enableInterviewReminder">
                      <interview-reminder-form :on="enableInterviewReminder" :selectedTemplate="selectedTemplate"
                                               :interviewReminders="interviewReminders"
                                               @form-block-change="formBlockChangeHandler"
                                               @update-template="updateTemplate"
                                               @form-blocked-change="formBlockChildChangeHandler"/>
                    </b-col>
                  </b-row>
                </div>
              </ValidationObserver>
            </card>
          </b-col>
        </b-row>
        <div v-if="!dialog && isGreenHouseEnabled">
          <StageList :activeMenu="activeMenu"/>
        </div>
        <confirmation-modal ref="saveChangesModal" header="Save changes"
                            question="You have made changes to this template, save changes before you leave?"
                            cancelBtnText="Cancel"
                            confirmBtnText="Save" @on-accept="saveTemplateChanges" @on-reject="changeTemplate"/>
        <confirmation-modal ref="reminderOffModal" header="Turning off Interview Reminders" question="Turning off will
          not cancel any reminder messages that have already been scheduled. <br><br>
          If you wish to cancel scheduled reminder messages, this can be done via the extension."
                            cancelBtnText="Keep on" confirmBtnText="Confirm turning off" confirmBtnType="danger"
                            contentClass="text-center" btnClass="justify-content-between" @on-accept="turnOffReminder"
                            @on-reject="keepOnReminder" :isClose="true" :showClose="false"/>
      </div>
      <!-- Onboarding Section -->
      <div v-if="activeMenu === 'onboarding'">
        <b-row>
          <b-col md="12">
            <card bodyClasses="p-3 pb-3">
              <base-switch v-model="isOnboarding" onText="On" offText="Off" @input="toggleOnboarding">
              </base-switch>
              <b-row class="mt-3">
                <b-col md="6">
                  <div class="d-flex align-items-center" v-if="isOnboarding">
                    <base-button type="primary"
                                 :disabled="onboardingTemplates.length >= availableTemplates || isNewTemplate"
                                 @click="createOnboardTemplate">
                      <i class="ni ni-fat-add"></i>New template
                    </base-button>
                    <div class="ml-3"><b>{{ onboardingTemplates.length }}/{{ availableTemplates }}</b></div>
                  </div>
                  <onboarding-list :isOnboarding="isOnboarding" :onboardings="onboardingTemplates"
                                   v-model="selectedOnboardTemplate" :formOnboardBlocked="formOnboardBlocked"
                                   :unsavedOnboardFormData="unsavedOnboardFormData"
                                   @save-onboard-confirmation="saveOnboardChangesConfirmation"
                                   @delete-onboard-template="deleteDummyTemplate"></onboarding-list>
                </b-col>
                <b-col md="6" v-if="isOnboarding && selectedOnboardTemplate">
                  <onboarding-form :isOnboarding="isOnboarding" :formOnboardBlocked="formOnboardBlocked"
                                   :selectedOnboardTemplate="selectedOnboardTemplate" :canCreate="canCreate"
                                   @update-onboard-template="updateOnboard"
                                   @form-onboard-block-change="formOnboardBlockChangeHandler"
                                   @enable-new-template="enableTemplate"></onboarding-form>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
        <confirmation-modal ref="onboardOffModal" header="Turning off Onboarding" question="Turning off will not cancel
        any onboarding messages that have already been scheduled.<br><br>
          If you wish to cancel scheduled onboarding messages, this can be done via the extension."
                            cancelBtnText="Keep on" confirmBtnText="Confirm turning off" confirmBtnType="danger"
                            contentClass="text-center" btnClass="justify-content-between" @on-accept="turnOffOnBoard"
                            @on-reject="keepOnBoard" :isClose="true" :showClose="false"/>
        <confirmation-modal ref="saveOnboardChangesModal" header="Save changes"
                            question="You have made changes to this template, save changes before you leave?"
                            cancelBtnText="Cancel"
                            confirmBtnText="Save" @on-accept="saveOnboardTemplateChanges"
                            @on-reject="changeOnboardTemplate"/>
      </div>

      <!-- Stage Section -->
      <div v-if="activeMenu === 'stage_changes' && isGreenHouseEnabled">
        <StageList :activeMenu="activeMenu"/>
      </div>
    </div>
  </dashboard-page-wrapper>
</template>
<script>
import DashboardPageWrapper from '@/components/App/UI/DashboardPageWrapper.vue';
import {mapActions, mapGetters} from 'vuex';
import InterviewReminderForm from './InterviewReminderForm.vue';
import InterviewRemindersList from './InterviewRemindersList.vue';
import {
  CREATE_USER_ONBOARGING_REQUEST,
  FETCH_INTERVIEW_REMINDERS_REQUEST,
  FETCH_USER_ONBOARDING_REQUEST,
  UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST,
  UPDATE_TEMPLATE_ONBOARDING_REQUEST,
  UPDATE_USER_INTERVIEW_REMINDER_REQUEST,
  UPDATE_USER_OFFICE_MSG_REQUEST,
  UPDATE_USER_ONBOARDING_REQUEST,
} from '@/store/storeActions';
import AlertCircleOutlineIcon from 'vue-material-design-icons/AlertCircleOutline.vue';
import ConfirmationModal from '@/components/App/modals/ConfirmationModal.vue';
import {accountTypes, templateTypes} from '@/store/userConstants';
import OnboardingList from './OnboardingList.vue';
import OnboardingForm from './OnboardingForm.vue';
import StageList from './stages/StageList.vue';

export default {
  components: {
    DashboardPageWrapper,
    InterviewReminderForm,
    InterviewRemindersList,
    AlertCircleOutlineIcon,
    ConfirmationModal,
    OnboardingList,
    OnboardingForm,
    StageList,
  },
  data: function() {
    return {
      selectedTemplate: null,
      formBlocked: false,
      unsavedFormData: null,
      dialog: true,
      enableInterviewReminder: false,
      firstLoad: true,
      activeMenu: 'interview',
      accountTypes,
      onboardLoadFirst: true,
      isOnboarding: false,
      formOnboardBlocked: false,
      unsavedOnboardFormData: null,
      selectedOnboardTemplate: null,
      availableTemplates: 5,
      onboardingTemplates: [],
      isNewTemplate: false,
      isGreenHouseEnabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'accountType',
      'userId',
      'userEmail',
      'userTimezone',
      'twilioNumberFormatted',
      'interviewReminderEnabled',
      'interviewReminders',
      'interviewReminderLoaded',
      'onboardingMessageEnabled',
      'onboardings',
      'onboardingLoaded',
    ]),
    canCreate() {
      return this.onboardingTemplates.length <= this.availableTemplates;
    },
  },
  watch: {
    interviewReminders: {
      handler: function(value) {
        this.selectedTemplate = (!!value.length) ?
          (!!this.selectedTemplate) ?
            (value.find((template) => template.id === this.selectedTemplate.id)) : value[0] : null;
        if (!this.selectedTemplate && value.length > 0) {
          this.selectedTemplate = value[0];
        }
      },
      immediate: true,
    },
    onboardings: {
      handler: function(value) {
        this.onboardingTemplates = value;
        this.isNewTemplate = this.onboardingTemplates.some((item) => item.id == null);
        this.selectedOnboardTemplate = (!!value.length) ?
          (!!this.selectedOnboardTemplate) ?
            (value.find((template) => template.id === this.selectedOnboardTemplate.id)) : value[0] : null;
      },
      immediate: true,
    },
    dialog: {
      handler: function() {
        if (this.userTimezone && this.twilioNumberFormatted) {
          this.dialog = false;
        }
      },
      immediate: true,
    },
    interviewReminderEnabled: {
      handler: function(value) {
        this.enableInterviewReminder = value;
      },
      immediate: true,
    },
    onboardingMessageEnabled: {
      handler: function(value) {
        this.isOnboarding = value;
      },
      immediate: true,
    },
  },
  updated() {
    if (this.userTimezone && this.twilioNumberFormatted) {
      this.dialog = false;
    }
    if (!this.enableInterviewReminder && !this.firstLoad) {
      this.$refs.reminderOffModal.openModal(this.enableInterviewReminder);
    }
    if (!this.isOnboarding && !this.onboardLoadFirst) {
      this.$refs.onboardOffModal.openModal(this.isOnboarding);
    }
  },
  mounted() {
    if (!this.interviewReminderLoaded && this.userTimezone && this.twilioNumberFormatted) {
      this.FETCH_INTERVIEW_REMINDERS_REQUEST();
    }
    if (!this.onboardingLoaded && this.userTimezone && this.twilioNumberFormatted) {
      this.FETCH_USER_ONBOARDING_REQUEST();
    }
  },
  methods: {
    ...mapActions([
      FETCH_INTERVIEW_REMINDERS_REQUEST,
      UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST,
      UPDATE_USER_INTERVIEW_REMINDER_REQUEST,
      UPDATE_USER_OFFICE_MSG_REQUEST,
      UPDATE_USER_ONBOARDING_REQUEST,
      FETCH_USER_ONBOARDING_REQUEST,
      UPDATE_TEMPLATE_ONBOARDING_REQUEST,
      CREATE_USER_ONBOARGING_REQUEST,
    ]),
    createTemplate: async function() {
      this.formBlocked;
    },
    formBlockChangeHandler: function({blocked, unsavedFormData}) {
      this.formBlocked = blocked;
      this.unsavedFormData = unsavedFormData;
    },
    saveChangesConfirmation: function(data) {
      this.$refs.saveChangesModal.openModal(data);
    },
    formOnboardBlockChangeHandler: function({blocked, unsavedFormData}) {
      this.formOnboardBlocked = false;
      this.unsavedOnboardFormData = unsavedFormData;
    },
    saveTemplateChanges: function({data, template}) {
      (!!template) && (this.selectedTemplate = template);
      (!!data && !!data.id) && this.updateTemplate({
        payload: {
          name: data.name, scheduleTime: data.time,
          message: data.message, automationReminder: this.enableInterviewReminder,
        }, id: data.id,
      });
    },
    saveOnboardTemplateChanges: function({data, onboard}) {
      (!!onboard) && (this.selectedOnboardTemplate = onboard);
      (!!data && !!data.id) && this.updateOnboard({
        payload: {
          name: data.name,
          message: data.message,
          scheduleTime: data.scheduleTime,
          automationType: 'onboardingMessage',
          delayType: data.templateType,
          delayInDays: data.templateType === this.templateTypes.SAME_DAY ? 0 : data.days,
        }, id: data.id,
      });
      (!!data && !data.id) && this.CREATE_USER_ONBOARGING_REQUEST({
        payload: {
          name: data.name,
          message: data.message,
          scheduleTime: data.scheduleTime,
          automationType: 'onboardingMessage',
          delayType: data.templateType,
          delayInDays: data.templateType === this.templateTypes.SAME_DAY ? 0 : data.days,
        },
      });
      if (this.isNewTemplate) {
        this.selectedOnboardTemplate = data;
      }
      this.formOnboardBlocked = false;
    },
    saveOnboardChangesConfirmation: function(data) {
      this.$refs.saveOnboardChangesModal.openModal(data);
    },
    updateTemplate: function({payload, id}) {
      this.UPDATE_INTERVIEW_REMINDER_BY_ID_REQUEST({payload, id});
      this.formBlocked = false;
    },
    changeTemplate: function({template}) {
      this.selectedTemplate = template;
    },
    changeOnboardTemplate: function({onboard}) {
      this.selectedOnboardTemplate = onboard;
    },
    turnOffReminder: function() {
      this.enableInterviewReminder = false;
      this.toggleInterviewReminder();
    },
    keepOnReminder: function() {
      this.enableInterviewReminder = true;
      this.toggleInterviewReminder();
    },
    turnOffOnBoard: function() {
      this.isOnboarding = false;
      this.updateOnboarding();
    },
    keepOnBoard: function() {
      this.isOnboarding = true;
      this.updateOnboarding();
    },
    isInterviewReminderEnabled: function() {
      this.firstLoad = false;
      if (this.enableInterviewReminder) {
        this.toggleInterviewReminder();
      }
    },
    formBlockChildChangeHandler: function(value) {
      this.formBlocked = value;
    },
    toggleInterviewReminder: function() {
      const payload = {
        enableInterviewReminder: this.enableInterviewReminder,
      };
      this.UPDATE_USER_INTERVIEW_REMINDER_REQUEST({payload});
      this.$store.state.auth.user.interviewReminderEnabled = this.enableInterviewReminder;
      if (this.enableInterviewReminder === true) {
        setTimeout(() => {
          this.FETCH_INTERVIEW_REMINDERS_REQUEST();
        }, 100);
      }
    },
    updateOnboarding: function() {
      const payload = {
        enableOnboarding: this.isOnboarding,
      };
      this.UPDATE_USER_ONBOARDING_REQUEST({payload});
      this.$store.state.auth.user.onboardingMessageEnabled = this.isOnboarding;
    },
    toggleMenuClass: function(activeTab) {
      if (this.twilioNumberFormatted) {
        this.activeMenu = activeTab;
        this.firstLoad = true;
        this.onboardLoadFirst = true;
      }
    },
    toggleOnboarding: function(value) {
      this.onboardLoadFirst = false;
      this.isOnboarding = value;
      this.updateOnboarding();
    },
    createOnboardTemplate: function() {
      if (this.formOnboardBlocked) {
        this.$refs.saveOnboardChangesModal.openModal(this.unsavedOnboardFormData);
      } else {
        const payload = {
          id: null,
          name: 'Onboarding template name',
          message: 'Onboarding template message',
          scheduleTime: '15:00',
          automationType: 'onboardingMessage',
          delayType: templateTypes.BEFORE,
          delayInDays: null,
        };
        this.canCreate && this.onboardingTemplates.unshift(payload);
        this.selectedOnboardTemplate = payload;
        this.isNewTemplate = true;
        this.formOnboardBlocked = false;
      }
    },
    updateOnboard: function(payload, id) {
      this.UPDATE_TEMPLATE_ONBOARDING_REQUEST(payload, id);
      this.formOnboardBlocked = false;
    },
    deleteDummyTemplate: function() {
      const index = this.onboardingTemplates.findIndex((item) => item.id === null);
      if (index !== -1) {
        this.onboardingTemplates.splice(index, 1);
      }
      this.isNewTemplate = false;
      this.selectedTemplate = null;
    },
    enableTemplate: function() {
      this.isNewTemplate = !this.canCreate;
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/sass/vuetify.scss';

.template-links {
  .btn-link.active {
    border-bottom: 2px solid;
    border-radius: 0;
  }

  .btn-link {
    box-shadow: none !important;
  }

}

.template-links .btn:hover,
.template-links .btn:focus,
.template-links .btn:active {
  transform: none !important;
}
</style>
